import React from "react"
import styled from "styled-components"

const Section = styled.section`
  max-width: 1152px;
  p {
    max-width: 40em;
    width: 100%;
  }
  p:last-of-type {
    margin-top: 1.5rem;
  }
`
const Map = styled.div`
  background: #48E5C2;
  border-radius: 4px;
  margin-top: 2.5rem;
  position: relative;
  overflow: hidden;
  a {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  @media screen and (min-width: 960px) {
    & {
      padding-left: 24rem;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 24rem;
    }
  }
  iframe {
    display: block;
    height: 400px;
    width: 100%;
  }
`

const Location = () => (
  <Section id="location">
    <h2>Location</h2>
    <p>Conference will be held at Temple Baptist Church:</p>
    <p>
      117 Marlin Road,
      <br />
      White House, TN
    </p>
    <Map className="location-bg">
      <a
        href="https://www.google.com/maps/dir//Temple+Baptist+Church+117+Marlin+Road/@36.4618097,-86.6967684,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88644edb797e527f:0xfb4ca7c4bbb31149!2m2!1d-86.675756!2d36.442035"
        target="_blank"
        rel="noopener"
      />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3209.641964509471!2d-86.67794468442638!3d36.442039295477514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88644edb797e527f%3A0xfb4ca7c4bbb31149!2sTemple+Baptist+Church!5e0!3m2!1sen!2sus!4v1561517942883!5m2!1sen!2sus"
        frameBorder="0"
        style={{ border: 0, margin: 0, padding: 0 }}
        allowFullScreen
      />
    </Map>
    <figure>
      <img src="" alt="" />
    </figure>
  </Section>
)

export default Location
