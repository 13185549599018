import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: flex-end;
    h4 {
      display: none;
    }
  }
  h4 {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-right: 1rem;
  }
  @media screen and (max-width: 800px) {
    .show {
      display: block;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.08);
      padding: 1rem;
      border-radius: 4px;
    }
    .show li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      text-align: center;
    }
    .show li:last-of-type {
      border: 0;
      margin-top: 1rem;
    }
    .show a:hover {
      background: rgba(255, 255, 255, 0.04);
    }
  }
`
const UL = styled.ul`
  display: none;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  li,
  a {
    display: block;
  }
  a {
    font-size: 0.875rem;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.1em;
    padding: 1.5rem 1rem;
    margin: 0 0.5rem;
    text-decoration: none;
    transition: all 0.35s ease;
    position: relative;
  }
  a:after {
    content: "";
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    display: block;
    transition: all 0.35s ease;
  }
  a:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
  }
  a:hover:after {
    background: #48e5c2;
  }
  @media screen and (min-width: 800px) {
    & {
      display: flex;
    }
  }
`
const BuyTicketsButton = styled.a`
  background: transparent;
  border: 1px solid #1c2030;
  border-radius: 50px;
  color: #1c2030;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1;
  margin-left: 1rem;
  padding: 0.75rem 1.5rem !important;
  text-transform: uppercase;
  transition: all 0.35s ease;
  :hover {
    border-color: #48e5c2;
    color: #48e5c2;
  }
`

function toggleMenu(e) {
  const menu = document.querySelector("nav ul")
  const h4 = document.querySelector("nav h4")

  if (e.target == h4) {
    menu.classList.toggle("show")
  } else {
    menu.classList.remove("show")
  }
}

const TopNav = () => (
  <Nav>
    <h4 onClick={toggleMenu}>Menu</h4>
    <UL onClick={toggleMenu}>
      <li>
        <Link to="#about">About</Link>
      </li>
      {/*<li>
        <Link
          to="#cfp"
        >
          Call for Proposals
        </Link>
      </li>*/}
      <li>
        <Link to="#location">Location</Link>
      </li>
      <li>
        <Link to="#sponsors">Sponsors</Link>
      </li>
    </UL>
  </Nav>
)

export default TopNav
