import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about"
import CFP from "../components/cfp"
import Location from "../components/location"

const Section = styled.section`
  dl {
    margin: 0;
    padding: 0;
  }
  dt,
  p:first-of-type::before {
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.125em;
    line-height: 1;
    margin-bottom: 0.5rem;
    color: #48e5c2;
    text-transform: uppercase;
  }
  p:first-of-type {
    font-size: 1.25rem;
    margin-top: 2rem;
  }
  p:first-of-type::before {
    content: "What";
    display: block;
  }
  dd {
    margin: 0;
    padding: 0;
  }
  div {
    display: flex;
    margin: 2.5rem 0 5rem;
    justify-content: center;
  }
`

const BuyTicketsButton = styled.a`
  background: #48e5c2;
  border: 0;
  border-radius: 50px;
  color: #121727;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1;
  margin: 0 0 2.5rem;
  padding: 1.25rem 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.35s ease;
  :hover {
    background: #fff;
  }
`

const Main = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  padding: 0 1rem;

  h1,
  h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
  }
  h1 {
    font-size: 2rem;
    margin: 3rem 1rem 1rem;
  }
  h2 {
    font-size: 0.75rem;
    margin: 2.5rem auto 1.5rem;
    color: #48e5c2;
    display: flex;
    align-items: center;
    max-width: 580px;
  }
  section:first-of-type h2 {
    margin-top: 0;
  }
  h2::before,
  h2::after {
    content: "";
    display: block;
    height: 1px;
    opacity: 0.175;
    width: 100%;
    background: #48e5c2;
    flex: 1 1 0%;
  }
  h2::before {
    margin-right: 1.5rem;
  }
  h2::after {
    margin-left: 1.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 580px;
  }
  svg {
    fill: #48e5c2;
    display: block;
    width: 45px;
    margin: 0 auto 1.5rem;
    text-align: center;
    height: 60px;
  }
  section:first-of-type p:first-of-type {
    margin-bottom: 1rem;
  }
  div dl {
    padding: 0 2rem;
  }

  @media screen and (min-width: 1200px) and (min-width: 800px) {
    & {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(6, 1fr) 80px;
      height: 620px;
      padding: 0;
    }
    svg {
      display: none;
    }
    h1 {
      font-weight: 100;
      font-size: 5rem;
      letter-spacing: 0.5em;
      text-align: right;
      grid-column: 1;
      grid-row: 1 / span 6;
      margin-bottom: 0;
      align-self: center;
      position: relative;
      padding-right: 2rem;
      margin-top: -24px;
    }
    h1 span {
      color: #48e5c2;
    }
    section {
      text-align: left;
      padding-left: 0;
    }
    section:first-of-type {
      grid-column: 2;
      grid-row: 1 / span 3;
      align-self: end;
      padding-bottom: 0.5rem;
    }
    section:last-of-type {
      grid-column: 2;
      grid-row: 4 / span 3;
      align-self: start;
      padding-top: 0;
    }
    section p {
      max-width: 470px;
      margin-left: 0;
    }
    h2 {
      margin-top: 0;
      margin-right: 0;
    }
    h2::before {
      display: none;
    }
    footer {
      grid-column: span 2;
      grid-row: 7;
      margin-top: 0;
    }
    div dl {
      padding: 0 2rem 0 0;
    }
    div {
      justify-content: flex-start;
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      site {
        siteMetadata {
          fltConferenceDate
        }
      }
    }
  `)
  return (
    <Layout id="flt">
      <SEO title="Home" />
      <Main>
        <h1>
          Faith Leads Tech <span></span>
        </h1>
        <Section>
          <p>
            A one-day, single-track conference bringing together followers of
            Jesus Christ centered on technology and innovation.
          </p>
        </Section>
        <Section>
          <div>
            <dl>
              <dt>When</dt>
              <dd>{data.site.siteMetadata.fltConferenceDate}</dd>
            </dl>
            <dl>
              <dt>Where</dt>
              <dd>
                Temple Baptist Church
                <br />
                White House, TN
              </dd>
            </dl>
          </div>
          {/*
          <BuyTicketsButton
            href="https://www.eventbrite.com/e/faith-leads-tech-conference-2019-tickets-64587179994"
            target="_blank"
            onClick={() => {
              window.gtag("event", "index-buy-ticket", {
                event_category: "link",
                event_label: "Index Buy Ticket Button",
              })
            }}
          >
            Buy Tickets
          </BuyTicketsButton>
          */}
        </Section>
      </Main>
      <div className="sub-content">
        <About />
        {/*<CFP />*/}
        <Location />
      </div>
    </Layout>
  )
}

export default IndexPage
