import React from "react"
import styled from "styled-components"

const Section = styled.section`
  max-width: 40em;
`

const Bolder = styled.b`
  font-weight: 700;
`

const CFP = () => (
  <Section id="cfp">
    <h2>Call For Proposals</h2>
    <h3 id="important-dates">Important Dates</h3>
    <ul>
      <li>
        <b>CFP Opens:</b> Tuesday, July 2nd, 2019
      </li>
      <li>
        <b>CFP Closes:</b> Wednesday, July 31st, 2019
      </li>
      <li>
        <b>Speakers Notified By:</b> Thursday, August 15th, 2019
      </li>
      <li>
        <b>Conference Date:</b> Friday, November 15th, 2019
      </li>
    </ul>
    <h3 id="proposal-requirements">Proposal Requirements</h3>
    <p>
      We want to hear how you or your ministry are innovating with or around
      technology!
    </p>
    <p>
      Share with us new ideas and concepts that push the boundaries of
      technology used across ministries today or how you reimagined existing
      products, processes, tooling or UX to build for the next generation of
      ministries.
    </p>
    <p>
      We’re looking for talks focusing on innovation and technology used
      throughout Christian Ministry, including but not limited to:
    </p>
    <ul>
      <li>Church Engagement</li>
      <li>Individual Spiritual Growth</li>
      <li>Church Management</li>
      <li>Mobile Apps</li>
      <li>Scripture Memorization</li>
      <li>Voice Technologies (Alexa, etc)</li>
      <li>Bible Study tools</li>
      <li>Sermon Prep</li>
      <li>Volunteer Management</li>
      <li>Technology Community and Ministry</li>
      <li>Lean Startup approach to Ministry</li>
      <li>Agile Methodology / Design Sprints in Ministry</li>
    </ul>
    <p>
      Please refer to our{" "}
      <a href="https://2018.faithleads.tech/speakers">2018 Speakers Page</a>{" "}
      information on previous topics.
    </p>
    <h3 id="additional-information">Additional Information</h3>
    <p>
      <Bolder>Talks will be 15 minutes (TED-style)</Bolder> and will speak to
      topics such as aspects of product design, product development, marketing,
      software development and practical use in ministry.
    </p>
    <p>Proposals we seek will include:</p>
    <ul>
      <li>
        A great story! Tell us about the problem you’re solving and how God is
        working through you to accomplish His work. Be sure to include the ups
        and the downs; give us your perspective and struggles along the way as
        you arrived to market.
      </li>
      <li>
        Technology, Design, Marketing, Product Management — keep all of these at
        a high level as our goal is to bring folks of varying backgrounds
        together, so be prepared to speak to each of them.{" "}
      </li>
      <li>
        Ministry Focus - new ideas and concepts that push the boundaries of
        technology used across Christian ministries today
      </li>
    </ul>
    <p>
      <a
        href="https://www.papercall.io/faith-leads-tech-2019"
        className="button"
        target="_blank"
        onclick={() => {
          window.gtag("event", "cfp-link", {
            event_category: "link",
            event_label: "Go To CFP Form",
          })
        }}
      >
        Go to CFP Form
      </a>
    </p>
  </Section>
)

export default CFP
