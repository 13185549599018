import React from "react"
import styled from "styled-components"

const Section = styled.section`
  max-width: 40em;
`

const Bolder = styled.b`
  color: #48e5c2;
  font-weight: normal;
`

const About = () => (
  <Section id="about">
    <h2>Theology + Technology</h2>
    <p>
      Faith Leads Tech is a one day, single track conference bringing together
      followers of Jesus Christ who seek to utilize technology and innovation to
      provide biblical solutions for life.
    </p>
    <p>
      Attendees from varying backgrounds -{" "}
      <Bolder>
        Developers, Designers, Marketers, Product Visionaries, Ministry Tech
        Leaders
      </Bolder>{" "}
      - will gain insights and perspectives from technology experts and ministry
      tech leaders on current innovations at a global, church, university and
      individual level. Keynotes and talks will explore a wide range of
      opportunities that currently exist at the intersection of Faith and
      Technology, as well as what innovations will be needed to provide
      effective technology for the church of tomorrow.
    </p>
    <p>
      For <Bolder>Technologists</Bolder>, this gathering will offer
      encouragement, showing that we as believers are not alone in our faith or
      efforts and offer support for being a strong believer in today’s technical
      environment.
    </p>{" "}
    <p>
      For <Bolder>Ministry Tech Leaders</Bolder>, the conference will provide an
      opportunity to learn of current innovations in ministry tech, share pain
      points that may be solved through technology, and network with other
      ministry tech leaders who are seeking to spread the gospel and grow the
      church with the help of technology.
    </p>
    <p>
      Throughout the day there will be opportunities for conversation with
      enterprises/companies, ministries, startups and individuals actively
      pursuing innovative technologies in ministry.
    </p>
    <p>
      Outcomes of the event will range from learning of{" "}
      <Bolder>shared struggles and successes</Bolder>, <Bolder>aligning</Bolder>{" "}
      of ministries around <Bolder>common goals</Bolder>, and{" "}
      <Bolder>awareness</Bolder> of innovative uses of technologies for{" "}
      <Bolder>Kingdom impact</Bolder>. Ultimately, it will{" "}
      <Bolder>build community between followers of Jesus Christ</Bolder>{" "}
      interested in furthering His Kingdom through{" "}
      <Bolder>Technology and Innovation.</Bolder>
    </p>
    <p>
      Faith Leads Tech is a ministry of{" "}
      <a href="http://templetoday.com">Temple Baptist Church</a>.
    </p>
  </Section>
)

export default About
