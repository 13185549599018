import React from "react"
import styled from "styled-components"

const StyledBullet = styled.span`
  opacity: 0.5;
  display: inline-block;
  margin: 0 0.5rem;
`
const Bullet = () => <StyledBullet>&bull;</StyledBullet>

export default Bullet
