import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import TopNav from "./topnav"

const StyledHeader = styled.header`
position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 100;
  @media screen and (min-width: 900px) {
    background: rgba(0,0,0,.25);
    align-items: center;
  }
  background: rgba(0,0,0,.5);
  border-bottom: 1px solid rgba(255,255,255,.05);
  border-radius: 0;
  padding: 0;
  &:hover {
    background: rgba(0,0,0,.5);
  }
`;
const H1 = styled.h1`
  margin: 0;
  padding: 0;
  border-right: 1px solid rgba(255,255,255,.05);
  span {
    display: none;
  }
  a {
    line-height: 1;
    padding: .5rem;
    height: 64px;
    width: 64px;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #48E5C2;
    }
    &:hover svg {
      fill: #111;
    }
  }
`;
const H1Svg = styled.svg`
  fill: #48E5C2;
  height: 32px;
  transition: all 0.35s ease;
`;

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <H1>
      <Link to="#flt">
        <H1Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 80">
          <path d="M50 20H24V4h12v12h4V0H20v20H0v20h20v40h20V51.5a15.45 15.45 0 0 0-4-10.39A14.52 14.52 0 0 0 34.89 40a15.45 15.45 0 0 0-10.39-4H4V24h44.34l6 6-6 6H42v4h8l10-10zM24 40h.5A11.51 11.51 0 0 1 36 51.5V76H24z" />
        </H1Svg>
        <span>Faith Leads Tech</span>
      </Link>
    </H1>
    <TopNav />
  </StyledHeader>
)

export default Header
