import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import styled from "styled-components"

const Div = styled.div`
  margin: 0 auto 4rem;
  max-width: 40em;
  & form {
    display: flex;
  }
  @media screen and (max-width: 800px) {
    form {
      flex-direction: column;
    }
    input[type="submit"] {
      border-radius: 0 0 4px 4px;
      display: block;
    }
    input[type="email"] {
      text-align: center;
      border-radius: 4px 4px 0 0;
    }
  }
`
const Email = styled.input`
  border: 0;
  border-radius: 4px 0 0 4px;
  color: #0b1021;
  display: block;
  font-family: inherit;
  font-size: 1.25rem; /* 20px */
  padding: 1.25rem; /*  20px */
  width: 100%;
`
const Submit = styled.input`
  align-self: stretch;
  background: #48E5C2;
  border: 0;
  border-radius: 0 4px 4px 0;
  color: #011627;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.25rem; /* 20px */
  padding: 1.25rem; /*  20px */
`

export default class MailingList extends React.Component {
  state = {
    email: null,
    signupConfirm: null,
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    window.gtag("event", "mailing-list", {
      event_category: "mailing-list",
      event_label: "Subscribe to Mailing List",
    })

    //console.log('email', this.state.email)
    addToMailchimp(this.state.email, {})
      .then(({ msg, result }) => {
        //console.log('msg', `${result}: ${msg}`)
        if (result === "success") {
          this.setState({ signupConfirm: true })
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  render() {
    return (
      <Div>
        {this.state.signupConfirm ? (
          "You are subscribed to updates!"
        ) : (
          <form onSubmit={this._handleSubmit}>
            <Email
              type="email"
              name="email"
              onChange={this._handleChange}
              placeholder="Enter your email address"
              aria-label="Email Address"
              required
            />
            <Submit type="submit" value="Sign up" name="subscribe" />
          </form>
        )}
      </Div>
    )
  }
}
