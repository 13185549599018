import React from "react"
import styled from "styled-components"

import Bullet from "./bullet"
import Sponsors from "../components/sponsors"
import MailingList from "../components/mailinglist"

const Container = styled.footer`
  padding: 2rem 1rem 0;
  text-align: center;
  h3 {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.125em;
    line-height: 1;
    margin: 0 0 4rem;
    text-transform: uppercase;
    text-align: center;
  }
  h3::before,
  h3::after {
    background: #fff;
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    opacity: 0.08;
    width: 100%;
  }
  h3::before {
    margin-right: 1.5rem;
  }
  h3::after {
    margin-left: 1.5rem;
  }
  .sponsors div {
    a {
      display: block;
      margin-bottom: 25px;
    }
    svg,
    img {
      width: 50%;
    }

    .fullwidth img {
      width: 100%;
    }
  }
  p {
    line-height: 1.6;
  }
  @media screen and (min-width: 800px) {
    .sponsors div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 80px;
      margin-bottom: 100px;
    }
  }
`

const Footer = () => (
  <Container>
    <h3>Kindly sponsored by</h3>
    <div className="sponsors">
      <Sponsors />
    </div>
    <h3>Sign up for future email updates:</h3>
    <MailingList />
    <h3>Contact Us</h3>
    <p>
      E:&nbsp;{""}
      <a
        href="mailto:info@faithleads.tech"
        onClick={() =>
          window.gtag("event", "footer-email-link", {
            event_category: "link",
            event_label: "Footer Email Link",
          })
        }
      >
        info@faithleads.tech
      </a>
      <Bullet />
      T:&nbsp;{""}
      <a
        href="https://twitter.com/faithleadstech"
        target="_blank"
        rel="noopener"
        onClick={() =>
          window.gtag("event", "footer-twitter-link", {
            event_category: "link",
            event_label: "Footer Twitter Link",
          })
        }
      >
        @faithleadstech
      </a>
      <Bullet />
      F:&nbsp;{""}
      <a
        href="https://facebook.com/faithleadstech"
        target="_blank"
        rel="noopener"
        onClick={() =>
          window.gtag("event", "footer-facebook-link", {
            event_category: "link",
            event_label: "Footer Facebook Link",
          })
        }
      >
        /faithleadstech
      </a>
    </p>
  </Container>
)

export default Footer
